<!--
<div class="d-sm-block d-none desktopInstance">
  <img src="../assets/images/fullDesktop.png">
</div>
-->
<div *ngIf="instance === '0'" class="firstInstance d-block" hidden>
  <div *ngIf="!modalSwitch" class="ia_popup_button" (click)="talkToIA()">
    <img src="../assets/images/Badge.png" alt="Vodafone Magica"/>
  </div>
  <iframe [width]="widthIframe" [height]="heightIframe" [src]="urlWeb" class="urlWeb"></iframe>
</div>

<div *ngIf="instance === '1'" class="firstInstance d-block">
  <!--
  <div class="fixed-top headerVodafone">
    <img src="../assets/images/webImages/nav-1.png">
    <img src="../assets/images/webImages/nav-2.png">
  </div>
  <div class="blockMagica">
    <div class="vodafoneMagicaText">
      <div class="firstInstanceText">
        <p>Prueba</p>
        <h1>Mágica</h1>
        <span>Mágica es una nueva forma de comunicarte con Vodafone, más humana, más sencilla. </span>
      </div>
    </div>
    <button class="firstInstanceButton" (click)="chatLog.length > 0 ? setInstance('3'): setInstance('2')">¿Hablamos?</button>
  </div>
  <img src="../assets/images/webImages/fullPageV3.png" class="w-100 imgBody">

  <div class="footerVodafone fixed-bottom">
  </div>
  -->
  <!--
  <div class="popup-chat" *ngIf="hideHomePopText">
    <div class="header-popup-chat">
      <h2><img src="../assets/images/popup-button/Wave.png" class="tv-icons" /> ¡Hola!</h2>
      <img src="../assets/images/popup-button/Close.png" class="close-popup" (click)="hideHomePopText = false"/>
    </div>
    <p>¿Necesitas ayuda para elegir una tarifa?</p>
    <button class="firstInstanceButton"  (click)="chatLog.length > 0 ? setInstance('3'): setInstance('2')">¡Te ayudo!</button>
  </div>
  <div *ngIf="!modalSwitch" class="ia_popup_button" (click)="chatLog.length > 0 ? setInstance('3'): setInstance('2')">     
    <img src="../assets/images/popup-button/Ball_1.png" alt="Vodafone Magica"/>
  </div>
  -->
</div>

<div *ngIf="instance === '2'" class="secondInstance d-grid">
  <div class="headerVodafone">
    <p></p>
    <img src="../assets/images/popup-button/Chevron--down.png" class="minimizar" (click)="minimize()">
  </div>

  <div class="middle-page">
    <div class="text-section">
      <p>¿Necesitas ayuda para elegir una tarifa?
        <br/>¿Tienes dudas sobre nuestros productos?
      </p>
    </div>
    <div class="default-btn-section">
      <span>El nuevo chat basado en Inteligencia Artificial de Vodafone puede responder a preguntas como:</span>
      <button class="default-btn" (click)="callOrquestadorFistTime(defaultQuestions[0])"><img src="../assets/images/popup-button/Wave.png" class="btn-icons" />
        <p>{{defaultQuestions[0]}}</p>
      </button>
      <button class="default-btn" (click)="callOrquestadorFistTime(defaultQuestions[1])"><img src="../assets/images/popup-button/Wave.png" class="btn-icons" />
        <p>{{defaultQuestions[1]}}</p>
      </button>
      <button class="default-btn" (click)="callOrquestadorFistTime(defaultQuestions[2])"><img src="../assets/images/popup-button/Wave.png" class="btn-icons" />
        <p>{{defaultQuestions[2]}}</p>
      </button>
      <button class="default-btn" (click)="callOrquestadorFistTime(defaultQuestions[3])"><img src="../assets/images/popup-button/Wave.png" class="btn-icons" />
        <p>{{defaultQuestions[3]}}</p>
      </button>
    </div>
    <div class="footer-text-section">
      <span class="smallText">Al iniciar una conversación aceptas la <a target="_blank" href="https://www.vodafone.es/c/statics/tc2-magica.pdf">Política de uso y Privacidad de Mágica</a>.
          No te pediremos tus datos personales ni es necesario que los aportes.</span>
    </div>
  </div>

  <div class="footerWatson">
    <div class="inputWatson">
      <input type="text" [(ngModel)]="chatEntryInputValue" placeholder="¿En qué puedo ayudarte?" autofocus (keydown.enter)="checkEntryInput(chatEntryInputValue)">
      <div class="sendButtonArrow" (click)="checkEntryInput(chatEntryInputValue)"></div>
    </div>
  </div>
</div>

<div *ngIf="instance === '3'" class="thirdInstance d-grid">
  <div class="headerVodafone">
    <span><strong>Mágica</strong></span>
    <img src="../assets/images/popup-button/Chevron--down.png" (click)="minimizeFromChat()">
  </div>

  <div></div>

  <div class="chatContainer" *ngIf="chatLog.length>0">

    <!--SPINNER AT CHAT BEGINING-->


    <div class="chat-message" id={{i.toString()}} *ngFor="let chatMessage of chatLog?.slice()?.reverse(); let i = index">

      <!-- Conversacoin del usuario -->
      <div *ngIf="chatMessage[0]!=''" class="userChatBox" [hidden]="chatMessage[4]">
        <div class="chat">
          <div class="chatDecorator">
            <span>Tú</span>
            <span>{{ chatMessage[1] | date: 'H:mm' }}</span>
          </div>
          {{ chatMessage[0] }}
        </div>
      </div>

      <!-- Conversacion del Box -->
      
      <!-- Seccion botones -->
      <ng-container *ngIf="chatMessage[3] == 'default startBtn'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div *ngIf="checkTypeof(chatMessage[2]) === 'object'" class="chatOptionsIA">
            <button (click)="callOrquestador('quiero contratar', false, false)">Si</button>
            <button (click)="callOrquestador('quiero saber más', false, false)">Seguir explorando</button>
          </div>
        </div>
      </ng-container>
      
      <!-- Seccion Botones respuesta del chatBot -->
      <ng-container *ngIf="chatMessage[3] == 'default' || chatMessage[3] == 'tarifasRow'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div *ngIf="checkTypeof(chatMessage[2]) === 'object'" class="chatOptionsIA">
            <ng-container *ngFor="let option of chatMessage[2]">
              <button (click)="addChat(option, $event)">{{ option.label }}</button>
            </ng-container>
          </div>

          <div *ngIf="checkTypeof(chatMessage[2]) !== 'object'" [ngClass]="{
            'chat': chatMessage[2].length > 1,
            'chatLoader': !chatMessage[2],
            'chatLoaderSpinner': this.timeoutreached
          }">
            <div class="chatDecorator" *ngIf="chatMessage[2]">
              <span><strong>Mágica</strong></span>
              <!--<span class="selectable-text">{{session_id}}</span>-->
              <span [innerHTML]="chatMessage[1] | date: 'H:mm'"></span>
            </div>
            <span *ngIf="!chatMessage[2] && !this.timeoutreached" class="loader"></span>
            <span class="textPensando" *ngIf="this.timeoutreached && !chatMessage[2]">
              {{ this.timeoutmessages[0] }} <i class="fa-solid fa-hourglass fa-spin"></i>
            </span>
            <ng-container *ngIf="chatMessage[4] == 'feedback'">
                <div class="chatBotText">
                  <span [innerHTML]="chatMessage[2]"></span>
                </div>
                <div *ngIf="chatMessage[4] == 'feedback' && chatMessage[6]" class="chatBotBtn" [id]="chatMessage[5]">
                  <!--<a (click)="sendFeedbackNoResponse(1, chatMessage[5])"><img [id]="chatMessage[5]+'1'" src="../assets/images/pulgar_arriba.png"></a>-->
                  <a (click)="sendFeedbackNoResponse(0, chatMessage[5])"><img [id]="chatMessage[5]+'0'" src="../assets/images/pulgar_abajo.png"></a>
                </div>
            </ng-container>
            <ng-container *ngIf="chatMessage[4] != 'feedback'">
              <span [innerHTML]="chatMessage[2]"></span>
            </ng-container>
            <ng-container *ngIf="chatMessage[4] != 'feedback' && chatMessage[6]">
              <br><input type="date" (change)="handleDateChange($event)">
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Seccion de dispositivos -->
      <ng-container *ngIf="chatMessage[3] == 'default dispositivos'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div [ngClass]="{ 'chat': chatMessage[3] }">
            <div class="chatDecorator" *ngIf="chatMessage[2]">
              <span><strong>Mágica</strong></span>
              <!--<span class="selectable-text">{{session_id}}</span>-->
              <span [innerHTML]="chatMessage[1] | date: 'H:mm'"></span>
            </div>
            <div class="tarifaBox">
              <span [innerHTML]="chatMessage[4]"></span>
              <!--             
              <div class="tarifaCardsCarrousel">
                <img class="dipositivosImg" [src]="processImagePath(chatMessage[5])"/>
              </div>
              -->
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Seccion tarjetas de las tarifas y los botones -->
      <ng-container *ngIf="chatMessage[3] == 'default tarifaCard'">
        <div class="iaChatBox" [ngClass]="i > 0 ? 'iaChatBoxDissabled' : ''" [class]="chatMessage[3]">
          <div [ngClass]="{ 'chat': chatMessage[3] }">
            <div class="chatDecorator" *ngIf="chatMessage[2]">
              <span><strong>Mágica</strong></span>
              <!--<span class="selectable-text">{{session_id}}</span>-->
              <span [innerHTML]="chatMessage[1] | date: 'H:mm'"></span>
            </div>
            <div class="tarifaBox">
              <span [innerHTML]="chatMessage[4]"></span>
              <div *ngIf="chatMessage[5] && chatMessage[5].length > 0" class="tarifaCardsCarrousel">
                <ng-container *ngFor="let tarifaCard of chatMessage[5]; let i = index">
                  <div class="tarifaCardsCarrouselCard">
                    <div class="tarifa-info-header" 
                         (click)="selectTarifa(tarifaCard[0])"
                         [ngClass]="{'selected': selectedTarifa === tarifaCard[0]}">
                      <div class="tarifa-price">
                        <div class="tarifa-price-value">
                          <p class="priceValue">{{ chatMessage[5][i][1].toString().split(',')[0] }}</p>
                          <div>
                            <p class="cents" *ngIf="chatMessage[5][i][1].toString().split(',')[1]">,{{chatMessage[5][i][1].toString().split(',')[1]}}</p>
                            <p class="priceText">€/mes</p>
                          </div>
                        </div>
                        <div class="tv-rate" *ngIf="chatMessage[5][i][4] == 'null' || chatMessage[5][i][4] == 0">
                          <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('max') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('seriefans') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('serielovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts')" src="../assets/images/tv-icons/max.png"/>
                          <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('disney') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('familyfans') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('familylovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts')" src="../assets/images/tv-icons/disney.png"/>
                          <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('prime') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('familylovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('serielovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts')" src="../assets/images/tv-icons/prime.png"/>
                          <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('netflix') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts_netflix')" src="../assets/images/tv-icons/netflix.png"/>
                          <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('dazn') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('dazntotal')" src="../assets/images/tv-icons/dazn.png"/>
                          <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('filmin') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('mascine') " src="../assets/images/tv-icons/filmin.png"/>
                          <img class="tvImg" *ngIf="chatMessage[5][i][2]!='NO'" src="../assets/images/tv-icons/70canales.png"/>
                        </div>
                        <div class="special-price" *ngIf="chatMessage[5][i][4] != 'null' && chatMessage[5][i][4] != 0">
                          <p>Precio especial {{chatMessage[5][i][5]}}</p>
                          <p>Después {{chatMessage[5][i][4]}}€/mes</p>
                        </div>
                      </div>
                      <div class="tarifa-info">
                        <strong><p [innerHTML]="getSanitizedMessage(chatMessage[5][i][0])"></p></strong>
                      </div>                      
                      <div class="special-price-tv-rate" *ngIf="chatMessage[5][i][4] != 'null' && chatMessage[5][i][4] != 0">
                        <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('max') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('seriefans') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('serielovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts')" src="../assets/images/tv-icons/max.png"/>
                        <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('disney') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('familyfans') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('familylovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts')" src="../assets/images/tv-icons/disney.png"/>
                        <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('prime') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('familylovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('serielovers') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts')" src="../assets/images/tv-icons/prime.png"/>
                        <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('netflix') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('todasotts_netflix')" src="../assets/images/tv-icons/netflix.png"/>
                        <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('dazn') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('dazntotal')" src="../assets/images/tv-icons/dazn.png"/>
                        <img class="tvImg" *ngIf="chatMessage[5][i][2].toLowerCase().toLowerCase().includes('filmin') || chatMessage[5][i][2].toLowerCase().toLowerCase().includes('mascine') " src="../assets/images/tv-icons/filmin.png"/>
                        <img class="tvImg" *ngIf="chatMessage[5][i][2]!='NO'" src="../assets/images/tv-icons/70canales.png"/>
                      </div>
                      <div class="tarifa-buttons">
                        <button class="iWantItBtn" (click)="iWantIt(tarifaCard, chatMessage[8], i+1)" 
                                [hidden]="selectedTarifa !== tarifaCard[0]">Lo quiero</button>
                        <button class="moreInfo" (click)="iWantToKnowMore(tarifaCard[0])" 
                                [hidden]="selectedTarifa !== tarifaCard[0]">Más Info</button>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Mostrar Texto intermedio -->
                  <div class="intermediate-text" *ngIf="i === 0 && chatMessage[9]">
                    <p>{{chatMessage[9]}}</p>
                  </div>
                </ng-container>
              </div>
              <!-- Mostrar Texto Final -->
              <span class="final-text" *ngIf="chatMessage[10]" [innerHTML]="chatMessage[10]"></span>              
              <span [innerHTML]="chatMessage[7] | date: 'H:mm'"></span>
            </div>            
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Seccion donde escribe el usuario -->
  <div class="footerWatson">
    <div class="inputWatson" [ngClass]="finalOfChat ? 'disabled' : ''" (keydown.enter)="callOrquestador(chatInputValue, true, false)">
      <input type="text" placeholder="¿En qué puedo ayudarte?" [(ngModel)]="chatInputValue" autofocus [disabled]="finalOfChat">
      <div class="sendButtonArrow" (click)="callOrquestador(chatInputValue, true, false)"></div>
    </div>
  </div>
</div>

<div class="popup-container" *ngIf="instance === '4' && !askForPhone">
  <div class="popup-content">
    <div class="popup-header">
      <div class="div-header"><p><img src="../assets//images/chat-bot.png"/> ¡No pierdas el hilo!</p></div>
      <div class="div-close" (click)="closePopup()">X</div>
    </div>
    <div class="popup-form">
      <p class="popup_text">Hola 👋 antes de empezar y para que no te pierdas nada, déjanos tu teléfono y te llamaremos para retomar esta conversación si lo necesitas.</p>
      <form>
        <input type="tel" placeholder="Tu número de teléfono" [(ngModel)]="userPhone" (input)="validatePhone()" name="phoneNumber">
        <span class="errorPhone" [hidden]="errorPhone">Telefono no válido</span>
        <div class="button-group">
          <button class="vfBtn" type="button" (click)="savePhone()" [disabled]="disabledSendBtn">Aceptar</button>
        </div>
        <div class="checkbox-group">
          <label>
            <input type="checkbox" [(ngModel)]="acceptPrivacyPolicy" name="acceptPrivacyPolicy" (change)="checkButton()"> Acepto la &nbsp;
              <a id="dsa-sm-privacidad"
                href="https://www.vodafone.es/c/conocenos/es/vodafone-espana/quienes-somos/legal-y-regulatorio/politica-de-privacidad-y-cookies/"
                target="_parent"
                class="measurec2c cboxElement"
                title="Vodafone One"
                sec_uuid="096f8cd23e21"
                sec_uuid_js:web.vodafone.es="faf2fa6903ba">
                política de privacidad
              </a>
          </label>
          <label>
            <input type="checkbox" [(ngModel)]="acceptCommercialCommunications" name="acceptCommercialCommunications"> Acepto recibir comunicaciones comerciales de Vodafone (opcional)
          </label>
        </div>
        <div class="button-group">
          <button class="vfBtn-white" type="button" (click)="setInstance('2'); askForPhone= true">Quizá en otro momento</button>
        </div>
      </form>
    </div>
  </div>
</div>