import { Component, Input, NgZone, OnInit, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  private sanitaizer = inject(DomSanitizer);
  vodafoneWeb = 'https://www.vodafone.es';
  urlDispositivos = 'https://www.vodafone.es/c/tienda-online/particulares/catalogo/';
  title = 'vodafoneApp'; //WEB TITLE

  @Input() adobeID?: string | null = '' // el codigo de adobe que recibe el iframe
  @Input() environmentValue?: string | null = '' // el codigo de adobe que recibe el iframe

  @Input() instance?: string | null = "1"; //ACTUAL ACTION INSTANCE
  //IT IS USED TO KNOW IN WHICH OF THE TRHEE PATHS WE ARE.
  // -> 1 -> VODAFONE MAIN PAGE, INTRODUCES TO VFMAGICA
  // -> 2 -> VODAFONE MAGICA CHAT PREVIEW
  // -> 3 -> VODAFONE MAGICA CONVERSATIONAL INSTANCE

  firstChat = true; //SPINNER FOR CHAT BEGINING - HTML LINE 56
  currentChatPath = ""; //TO DETECT IF ITS FIRST INTERACTION, BECAUSE FIRST INPUT INST GLOBAL, ITS DUPLICATED
  //THE FIRST INPUT BELONGS TO INSTANCE 2, THE CHAT INPUT BELONGS TO INSTANCE 3
  //IT IS NOT AN INPUT MODULE, IT IS AN INDIVIDUAL, CLONED INPUT
  session_id = ""; //SESSION ID FOR CORRECT API CONTEXT CONVERSATION
  source: string = '';

  //--CHAT BOX DATE VARIABLES--
  date: Date | any;
  currentHour = undefined;
  currentMinute = undefined;
  //---------------------------

  //----CHAT BOX VARIABLES----
  chatLog: [string, Date | any, any, string, any?, any?, boolean?, any?, any?, any?, any?, any?][] = [];
  resultAPI: string[] = [];
  recordSearch: string[] = [];
  //ARRAY FOR CHAT CONVERSATION INSTANCE
  //[chatMessage, date, this.iamessage, "DEFAULT", this.iaoptions]);

  inputDisabled: boolean = true; //BOOLEAN TO DISABLE INPUT TILL IA RESPONSE IS PRINTED
  finalOfChat: boolean = false; //BOOLEAN TO DISABLE INPUT WHEN FINAL

  timeoutreached: boolean = false;
  timeoutid: any = null;
  stopCountdown: boolean = false;
  secondsremaining: any = 10;
  timeoutmessages: string[] = ["La Inteligencia Artificial todavía no llega a ser igual de rápida que la mente humana, pero estoy trabajando en ello… ¡espera un momento por favor!", "Estamos trabajando en ser una Inteligencia Artifical más rápida, ¡espera un momento por favor!"];

  chatEntryInputValue: string = ''; //FIRST INPUT VALUE, FIRST INTERACTION WITH CHAT
  chatInputValue: string = ''; //INPUT VALUE FOR CHAT INTERACTIONS
  iamessage: string | any; //MESSAGE OF THE IA OUTPUT
  intermediateText: string | any; //MESSAGE OF THE IA OUTPUT
  finalText: string | any; //MESSAGE OF THE IA OUTPUT
  iaoptions: string[] = []; //MESSAGE OPTIONS ARRAY OF THE IA OUTPUTS
  tarifaCardNames: string[] | null = []; //TARIFA CARD NAME TO SHOW CARD PNG
  tarifaCardPrices: string[] | null = []; //TARIFA CARD PRICE TO SHOW CARD PNG
  tarifaCardInfo: string[] | null = []; //TARIFA CARD PRICE TO SHOW CARD PNG
  haveDate: boolean = false;
  tarifaSeleccionada: string = '';

  url_servicio:string = environment.apiOrquestador; //url del orquestador //para local usar apiOrquestadorLocal
  url_feedback:string = environment.apiFeedback; //url del feedback
  url_bbdd:string = environment.apiBBDD; //url del bbdd

  modalSwitch: boolean = false; //variable para controlar si el popup esta abierto o cerrado
  showWeb: boolean = false;
  urlWeb: any = '';
  onlyMoreBtn: boolean = false;
  heightIframe: any;
  widthIframe: any;
  wantKnowMore: boolean = false; //variable para guardar si el usuario quiere saber más o no
  wantKnowMoreQuestionDone: boolean = false; //variable para guardar si el usuario ha relizado ya la pregunta de quiere saber más o no
  tarifaCardNameSelected: string = ''; // variable para almacenar el nombre de la tarifa seleccionada
  focusId:string = ''
  chatLogId: [any?] = [];
  sourceLog: [any?, any?] = [];
  isPortability: boolean = false;
  searchTarifas: any = [];
  showCarrusel: boolean = false;
  tvSelected: string = 'hbo_img'
  tvList: string[] | null = []; // variable para guardar el listado de las tv
  showTarifas: boolean = false //variable para saber si se han pintado las tarifas
  firstCall: boolean = true
  phone1: string | null | undefined
  phone2: string | null | undefined
  withPhone:boolean = false
  userPhone: string = ''
  errorPhone: boolean = true
  askForPhone:boolean = false
  acceptPrivacyPolicy = false;
  acceptCommercialCommunications = false;
  disabledSendBtn: boolean = true
  contador: number = 0;
  intervalId: any;
  hideHomePopText:boolean = true

  conversationStarterEvent:boolean = false //evento que avisa a donde se pinta el iframe que se ha iniciado la conversacion
  selectedTarifa: string = ''; // Variable para almacenar la tarifa seleccionada
  question_id:string = ''

  defaultQuestions: string[] = [
    '¿Cuál es la tarifa más barata?',
    '¿Qué ofertas de Black Friday tenéis en Vodafone?',
    '¿Qué promociones tenéis a día de hoy?',
    '¿Qué planes de fibra y móvil incluyen Netflix?'
  ];

  /**
   * constructor
   * @param ngZone 
   */
  constructor(private ngZone: NgZone, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.session_id = "";//inicializamos el session_id
    this.urlWeb = this.sanitaizer.bypassSecurityTrustResourceUrl(this.vodafoneWeb)
  }

  /**
   * Funcion que se ejecuta cuando carga la pagina
   */
  ngOnInit(): void {
    // Agregar el listener al cargar el componente
    // window.addEventListener('beforeunload', this.clearSessionStorage);
    this.doAutoScroll(); // AUTO SCROLL FUNCTION WHEN PAGE IS LOADED
    
    //IMPORTANTE No quiren que se vea el boton porque lo ponen ellos, por eso comentamos esta linea
    //this.instance = '1';
  
    // obtenemos los valores que pasan por parametro en la URL
    this.route.queryParams.subscribe(params => {
      const adobeIDAux = params['adobe_id'];
      const instanceAux = params['instance'];
      const phone = params['phone'];
      const environmentValue = params['environment'];
      //console.log('adobeID:', adobeIDAux);
      //console.log('instance:', instanceAux);
      //console.log('phone:', phone);
  
      // comprobamos que valores vienen para asignarlos a nuestras variables
      if (environmentValue) {
        this.environmentValue = environmentValue;
      }
      if (adobeIDAux) {
        this.adobeID = adobeIDAux;
      }
      if (instanceAux) {
        this.instance = instanceAux;
      }
      if (phone) {
        this.withPhone = true;
      }
  
      this.loadFromStorage();
  
      console.log('environment:', this.environmentValue);
      console.log('adobeID:', this.adobeID);
      console.log('instance:', this.instance);
      console.log('withPhone:', this.withPhone);
      console.log('');
  
      // comprobamos que si mandan desde la web de vodafone la instancia a 3 y que no haya conversacion, de ser asi mostramos la pantalla 2 (pantalla negra)
      if (this.instance == '3' && this.chatLog.length < 1) {
        this.instance = '2';
      } else {
        console.log('Inicio contador tiempo sesión, max 2 minutos');
  
        this.contador = 0; // iniciamos el contador
  
        this.ngZone.run(() => {
          // inicio el control de un intervalo de 1 minuto, después de este tiempo se limpiarán los valores de memoria de la sesión del navegador
          this.intervalId = setInterval(() => {
            this.contador++;
            //console.log('contador', this.contador);
            //sessionStorage.setItem('timeLog', this.contador.toString());

            if (this.contador >= 1800) { // 30 minutos //2 min = 120
              this.limpiarSessionStorage();
              clearInterval(this.intervalId);
            }
          }, 1000); // 1 segundo
          console.log('');
        });
      }
    });
  }
  
  clearSessionStorage() {
    sessionStorage.clear();
  }

  ngOnDestroy(): void {
    //window.removeEventListener('beforeunload', this.clearSessionStorage);
    clearInterval(this.intervalId);
  }

  /**
   * funcion que limpia la memoria del navegador
   */
  limpiarSessionStorage(): void {
    sessionStorage.clear();
    console.log('Fin contador tiempo 30 min, limpiamos sesionstorage');
    this.instance = '2'; // Cambiar la instancia a 2
    this.chatLog = []; // reinicio el historial
    this.contador = 0 //reinicio el contador
  }
  
  /**
   * funcion que comprueba si hay datos guardados en la memoria de la sesion del navegador,
   * si hay datos le asigna valor 3 al instance para poner la pantalla en blanco y cargar la conversacion,
   * tambien enviamos a la web de vodafone el valor del instance que tienen que mandar
   */
  loadFromStorage() {
    //console.log('loadFromStorage', this.hideHomePopText)
    // Obtener el valor guardado en el almacenamiento local
    const chatLogsessionStorage = sessionStorage.getItem('chatLog');
    const sourceLogsessionStorage = sessionStorage.getItem('sourceLog');
    //console.log('chatLogsessionStorage: ', chatLogsessionStorage)
    //console.log('this.instance: ', this.instance)

    let instanceAux = '2'

    if (chatLogsessionStorage && sourceLogsessionStorage) {
      // Convertir la cadena JSON de vuelta a un objeto JavaScript
      this.chatLog = JSON.parse(chatLogsessionStorage);
      this.sourceLog = JSON.parse(sourceLogsessionStorage);
      console.log('Objeto cargado del storage:', this.chatLog, this.sourceLog);
      instanceAux = '3'
    } else {
      //console.log('hideHomePopText', this.hideHomePopText)
      setTimeout(() => {
        this.hideHomePopText = true; // Muestra el div después de 3 segundos
      }, 3000); // 3000 milisegundos = 3 segundos
    }
    console.log('instanceToSend: ', instanceAux)
    window.parent.postMessage('instance=' + instanceAux, '*');
  }

  //AUTO SCROLL FUNCTION
  doAutoScroll() {
    window.onload = function () {
      window.scrollTo({ top: 420, behavior: 'auto' });
      //420 IS, IN PX, THE HEIGHT OF THE VFMAGICA PRESENTATION BOX
      //IN THE FIRST INSTANCE, IT IS HIDDEN, USER MAY SCROLL UP TO SEE IT
    }
  }

/**
 * funcion que dice que pantalla hay que pintar, recibe el numero de la pantalla a pintar
 * 1-Patalla Inicio, donde esta el pop up y el boton de hablamos (pantalla blanca y boton, pero esta opcion no quieren que se vea porque ellos pintan el boton)
 * 2-Pantalla intermedia, menu superior para volver, un texto y la caja de texto para que introduzcan la consulta (pantalla negra)
 * 3-Chat con IA (orquestador) (pantalla blanca)
 * @param instance 
 */
  //INSTANCE CHANGER FUNCTION
  setInstance(instance: string) {
    this.instance = instance; //UPDATE INSTANCE    

    //comprobamos si es la primera vez que abre el chat con la IA o si ya hizo consultas, para mostrar una pantalla u otra
    if (this.currentChatPath == '' || this.chatLog.length == 0) { 
      this.currentChatPath = "firstInteraction";
    } else {
      this.instance == "3";
      this.currentChatPath = "secondInteraction";
    }
    //console.log('setInstance - currentChatPath: ',this.currentChatPath)

    //comprobamos que instancia (pantalla) hay que ejecutar
    if (this.instance == "3") {
      // impedimos que pueda escribir el usuario en la caja de texto
      this.inputDisabled = true;
      //llamamos al orquestador
      this.firstCallOrquestador();

      //this.makeWatsonHandShake();
    }
    else {
      //desactivamos el modal
      this.modalSwitch = false;
    }

  }

  /**
   * funcion que devuelve el tipo de la variable que recibe
   * @param value 
   * @returns 
   */
  checkTypeof(value: any) {
    //console.log('checkTypeof - ', value)
    return typeof(value);
  }

  /**
   * Funcion que llama al orquestador le envia la primera consulta que hace el usuario, le manda el texto que introduce el usuario
   */
  async firstCallOrquestador() {  
    console.log('');
    console.log('INICIO');
    console.log('1º Call Orquestador - enviamos: ', this.chatEntryInputValue);
    this.sourceLog = [];
    
    if (this.chatLog.length < 1 && this.currentChatPath.length > 0) {      
      this.date = new Date();
      
      this.ngZone.run(() => {
        this.chatLog.push([this.chatEntryInputValue, this.date, '', "default", undefined, undefined, undefined, undefined]);
        this.chatLogId.push('vm_' + this.chatLog.length);
        this.finalOfChat = true;
      });
      
      let urlAUX = this.url_servicio + encodeURIComponent(this.chatEntryInputValue);
      if (this.adobeID && this.firstCall) {
        if (this.environmentValue != '') {
          urlAUX += '&adobe_id=' + this.adobeID + '&environment=' + this.environmentValue;
        } else {
          urlAUX += '&adobe_id=' + this.adobeID;
        }
        this.firstCall = false;
      }
      //console.log('url orquestador:', urlAUX);

      const events = new EventSource(urlAUX);
      let start_callAPI_time = performance.now(); // Tiempo de inicio de la llamada
      //console.log('start_callAPI_time', start_callAPI_time.toFixed(2) + ' segundos');
      let end_callAPI_time = 0
      let start_response_time = 0
      let end_response_time = 0         
      let total_response_time = 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
      let total_callAPI_time = 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
      let contAux = 0;

    // Manejo del tiempo de espera (20 segundos)
      const timeoutPromise = new Promise<void>((_, reject) => 
      setTimeout(() => reject(new Error('timeout')), 40000) // 40 segundos de espera
      );
  
    // Promesa que maneja la respuesta del evento
      const responsePromise = new Promise<void>((resolve) => {
        events.onmessage = (event) => {

          const parsedData = event as any;
          //console.log('json', JSON.parse(parsedData.data))
          let mensaje_stream = JSON.parse(parsedData.data).response.message;
          this.showCarrusel = JSON.parse(parsedData.data).response.carrusel === 'yes';
          let source = JSON.parse(parsedData.data).source;
          let question_id = JSON.parse(parsedData.data).question_id;
  
          this.ngZone.run(() => {
            this.source = source;
            this.sourceLog.push([source, mensaje_stream]);
            if (contAux < 1){
              end_callAPI_time = performance.now(); // Tiempo de inicio de la llamada
              //console.log('end_callAPI_time', end_callAPI_time + ' segundos');
              start_response_time = performance.now(); // Tiempo de inicio de la llamada
              //console.log('start_response_time', start_response_time + ' segundos');
            }
            contAux++
            //console.log(contAux)
          });
  
          if (JSON.parse(parsedData.data).session_id != null) {
            this.ngZone.run(() => {
              this.session_id = JSON.parse(parsedData.data).session_id;
              //console.log(this.session_id)
            });
          }
  
          if (JSON.parse(parsedData.data).response.message && source !== 'API') {
            this.ngZone.run(() => {
              //console.log('respuesta orquestador 2: ' + mensaje_stream);
              this.filterMessage(mensaje_stream, source, question_id);
            });
          }
    
          if (JSON.parse(parsedData.data).response.document_url !== undefined) {
            this.ngZone.run(() => {
              this.chatLog.push(['', this.date, JSON.parse(parsedData.data).response.document_url, "default", undefined, undefined, undefined]);
              this.chatLogId.push('vm_' + this.chatLog.length);
            });
          }
  
          if (JSON.parse(parsedData.data).response.last_message) {
            console.log('Historial ', this.chatLog);
            console.log('FIN');
            events.close();
            this.ngZone.run(async () => {              
              end_response_time = performance.now(); // Tiempo de fin de la llamada  
              //console.log('end_response_time', end_response_time.toFixed(2) + ' segundos');          
              total_response_time = start_response_time ? (end_response_time - start_response_time) / 1000 : 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
              total_callAPI_time = end_callAPI_time ? (end_callAPI_time - start_callAPI_time) / 1000 : 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
              console.log('Tiempo que tarda en contestar la llamada:', total_callAPI_time + ' segundos');
              console.log('Tiempo que tarda desde que empieza a contestar hasta que termina:', total_response_time + ' segundos');
      
              this.checkShowFeedback();
        
              let auxText = this.chatLog.length > 0 && this.chatLog[this.chatLog.length - 1][2] && this.chatLog[this.chatLog.length - 1][2][0]
                ? this.chatLog[this.chatLog.length - 1][2][0].label
                : null;
        
              let auxTextDispositivos = this.chatLog.length > 1 && this.chatLog[this.chatLog.length - 2][2]
                ? this.chatLog[this.chatLog.length - 2][2]
                : null;
        
              let auxTextNo = this.chatLog.length > 2 
                ? this.chatLog[this.chatLog.length - 3]
                : null;
        
              let auxTextDispositivos2 = this.chatLog.length >= 5 && this.chatLog[this.chatLog.length - 5][2]
                ? this.chatLog[this.chatLog.length - 5][2]
                : null;
        
              //console.log('auxText', auxText)
              //console.log('auxTextDispositivos', auxTextDispositivos)
              //console.log('auxTextNo', auxTextNo)
              //console.log('auxTextDispositivos2', auxTextDispositivos2)
              if (auxText != undefined && (auxText.includes('Fibra de 600MB')
                || auxText.includes('1 línea móvil') || auxText.includes('25 GB')
                || auxTextDispositivos.includes('dispositivos')
                || (auxTextDispositivos.includes('dispositivos') && auxTextNo && auxTextNo.includes('No'))
                || auxText.includes('Fibra y móvil')
                || (auxTextDispositivos2 && auxTextDispositivos2.includes('dispositivos')))) {
                this.finalOfChat = true;
              } else {
                this.finalOfChat = false;
              }
              this.firstChat = false;
              if(JSON.parse(parsedData.data).insert_bbdd && total_callAPI_time != 0 && total_response_time != 0){
                this.callApiBBD(
                  JSON.parse(parsedData.data).insert_bbdd,
                  JSON.parse(parsedData.data).response,
                  total_callAPI_time.toFixed(4),
                  total_response_time.toFixed(4)
                )
              }
                this.chatEntryInputValue = '';
            });
            resolve(); // Resolvemos la promesa cuando se recibe la última respuesta
          }
        };
      });
  
      try {
        await Promise.race([responsePromise, timeoutPromise]);
      } catch (error) {
        console.error(error);
        this.ngZone.run(() => {
          // Mostrar el mensaje de error
          const errorMessage = 'Vaya, parece que estoy teniendo problemas con la conexión. Inténtalo de nuevo más adelante.'
          this.chatLog.push(['', this.date, errorMessage, "default", undefined, undefined, undefined]);
          this.callApiBBD(null, errorMessage, null, null);
          this.finalOfChat = false; // Asegúrate de permitir que el usuario vuelva a intentar
        });
        //events.close(); // Cerrar la conexión si hay un timeout
      }
    }
  }
  
  /**
   * funcion que manda datos a la bbdd
   * @param responseTime 
   */
  async sendTimingDataToBBDD(responseTime: any) {
      const body = {
          session_id: this.session_id,
          response_time: responseTime
      };
      console.log('body:', body);

      const responseAPI = await fetch(this.url_bbdd, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
      });

      const data = await responseAPI.json();
      console.log('Datos de tiempo enviados a la BBDD:', data);
  }
  

  /**
   * funcion que filtra los mensajes recibidos por el orquestador, dependiendo de su contenido pintamos diferentes elementos
   * Tipos de contenidos:
   * '[' => botones
   * '##' => tarifas
   * 'Dispositivos' => dispositibos
   * otros => el resto de texto, comprueba si el texto tiene que seguir rellenando
   * @param message 
   */
  filterMessage(message: any, source :any, question_id: any) {  
    //console.log('filterMessage');
    const index = this.chatLog.findIndex(log => log[2].includes('Vaya, parece que estoy teniendo problemas con la conexión.'));
    if (index !== -1) {
      this.chatLog.splice(index, 1);
      this.finalOfChat = true; // Asegúrate de no permitir que el usuario vuelva a intentar
    }

    //console.log('filterMessage message', message)
    //comprobamos si el mensaje es un array de objetos
    if (message.includes("[")){
      //console.log('orquestador manda botones')
      //añadimos al array el valor que devuelve el orquestador de la consulta
      //para que salga como el chatBot el valor debe ir en la 3 posicion
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push(['', this.date, JSON.parse(message), "default", undefined, undefined, undefined]);
      this.chatLogId.push('vm_'+this.chatLog.length)  
      this.showTarifas = false
      //console.log('Source: ' + this.source + ' - Respuesta orquestador: ', message)
    } else if (message.includes('##textoTarifas')){ //comprobamos si el mensaje trae tarifas a pintar
      //console.log('orquestador manda textoTarifas')
      //console.log('message', message);
      this.showTarifas = true
      
      // Separar el mensaje por los delimitadores '##'
      const partes = message.split(/##/);
      //console.log('partes', partes);

      // Filtrar partes vacías y eliminar el primer elemento (que es 'filterMessage message')
      const elementosFiltrados = partes.filter((parte: string, index: number) => {
        return parte.trim() !== '' && index > 0 && parte != "textoTarifas" && parte != "tarifaCarrousel" && parte != "textoIntermedio" && parte != "textoFinal"; // Ignorar el primer elemento vacío
      });

      //console.log('elementosFiltrados', elementosFiltrados);
      //console.log('');

      //guardamos el texto inicial
      if (message.includes('##textoTarifas')) {
        if (elementosFiltrados[0].includes('\\n')){
          this.iamessage = elementosFiltrados[0].replace('\\n', '')
        } else {
          this.iamessage = elementosFiltrados[0].replace('\\n', '')
        }
        //console.log('texto inicial', this.iamessage);
      }
      //console.log('');

      //guardamos el texto intermedio
      if (message.includes('##textoIntermedio')) {
        if (elementosFiltrados[2].includes('\\n')){
          this.intermediateText = elementosFiltrados[2].replace('\\n', '')
        } else {
          this.intermediateText = elementosFiltrados[2]
        }
        //console.log('texto intermedio', this.intermediateText);
      }
      //console.log('');

      //guardamos el texto final
      if (message.includes('##textoFinal')) {
        if (elementosFiltrados[elementosFiltrados.length-1].includes('\\n')){
          this.finalText = elementosFiltrados[elementosFiltrados.length-1].replace('\\n', '') 
        } else {
          this.finalText = elementosFiltrados[elementosFiltrados.length-1]
        }
        //console.log('texto final', this.finalText);       
      }

      // recorremos el array buscando las tarifas
      this.tarifaCardNames = [];      
      if (message.includes('##textoFinal')) {
        for(let index = 0; index < elementosFiltrados.length-1; index++) {
          if (index != 0 && index != 2)
            this.tarifaCardNames?.push(elementosFiltrados[index].split('#'))
        };
      } else {
        for(let index = 0; index < elementosFiltrados.length; index++) {
          if (index != 0 && index != 2)
            this.tarifaCardNames?.push(elementosFiltrados[index].split('#'))
        };
      }
      console.log('tarifaCardNames: ', this.tarifaCardNames)
      //console.log('');

      this.selectedTarifa = ''; // Variable para almacenar la tarifa seleccionada
      this.selectedTarifa = this.tarifaCardNames[0][0]; // Selecciona el primer elemento
      //console.log('selectedTarifa', this.selectedTarifa)

      //añadimos al array el valor que devuelve el orquestador de la consulta
      //para que salga como el chatBot el valor debe ir en la 3 posicion
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios y lista de imagenes TV a pintar y url, question_id, texto intermedio, texto final])
      this.chatLog.push(['', this.date, message, "default tarifaCard", this.iamessage, this.tarifaCardNames, false, null, question_id, this.intermediateText, this.finalText]);
      this.chatLogId.push('vm_'+this.chatLog.length)
      //console.log('chatLog', this.chatLog)

      //envio feedback cuando pintamos las tarifas
      let tarifasFeedback = this.getTarifasForFeedback(message);
      this.sendFeedbackCarruselNoResponse(question_id, message, tarifasFeedback)   
    } else if (message.includes('##Dispositivos')) {
      //console.log('orquestador manda Dispositivos')
      this.showTarifas = false
      //console.log('Source: ' + this.source + ' - Respuesta orquestador: ', message)
      let inicioIndice = message.indexOf('#');
      let primeraFrase = message.substring(0, inicioIndice);
      this.chatLog.push(['', this.date, primeraFrase, "default dispositivos",primeraFrase,'dispositivos',false, undefined]);
    } else if (message != '' && !message.includes('-1')) {
      //console.log('orquestador manda texto')
      this.showTarifas = false
      //añadimos al array el valor que devuelve el orquestador de la consulta
      //para que salga como el chatBot el valor debe ir en la 3 posicion      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      //escribir en la misma linea 
      if(this.chatLog.length >1 && this.chatLog[this.chatLog.length-1][0] == '' && this.chatLog[this.chatLog.length-1][3] != 'default dispositivos' && this.chatLog[this.chatLog.length-1][3] != 'default startBtn') {
        let aux_content = this.chatLog[this.chatLog.length-1][2]
        //console.log('aux_content ', aux_content)
        aux_content += message
        if(source == 'WATSON')
          this.chatLog[this.chatLog.length-1] = (['', this.date, aux_content, "default", 'feedback', question_id, false]);
        else 
          this.chatLog[this.chatLog.length-1] = (['', this.date, aux_content, "default"]);
      } else {
        //escribir en una nueva linea
        //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
        this.chatLog.push(['', this.date, message, "default",undefined,undefined,undefined]);
        this.chatLogId.push('vm_'+this.chatLog.length)
        //console.log('Source: ' + this.source + ' - Respuesta orquestador: ', message)
      }

      if (message.includes('Teléfono 1') || message.includes('teléfono 1') || message.includes('telefono 1')) {
        //guardamos el valor de los telefonos
        const parser = new DOMParser();
        const doc = parser.parseFromString(message, 'text/html');
    
        this.phone1 = doc.querySelector('ul li strong')?.textContent;
        this.phone2 = doc.querySelector('ul li:nth-child(2) strong')?.textContent;
        console.log('this.phone1',this.phone1)
        console.log('this.phone2',this.phone2)
      }
    }
  }

  /**
   * funcion que llama al orquestador para obtener la informacion, le manda el valor que introduce el usuario por pantalla
   * @param valueToSend 
   */
  async callOrquestador(valueToSend: string, printSendText: boolean, newCicle: boolean) {
    if (valueToSend.length > 0) {
      this.sourceLog = [];
      console.log('');
      console.log('INICIO');
      console.log('2º Call Orquestador - enviamos: ', valueToSend);
  
      // Inicializamos la fecha y hora
      this.date = new Date();
      this.iamessage = '';
      this.iaoptions = [];
      this.onlyMoreBtn = false;
  
      this.ngZone.run(() => {
        if (printSendText) {
          this.chatLog.push([valueToSend, this.date, '', "default", undefined, undefined, undefined]);
        } else {
          this.chatLog.push([valueToSend, this.date, '', "default", true, undefined, undefined]);
        }
        this.chatLogId.push('vm_' + this.chatLog.length);
        this.finalOfChat = true;
        this.chatInputValue = '';
      });
  
      const url = this.url_servicio + encodeURIComponent(valueToSend) + '&session_id=' + this.session_id;
      console.log('url orquestador:', url);
  
      //lanza la llamada al servidor
      const events = new EventSource(url);
      
      let start_callAPI_time = performance.now(); // Tiempo de inicio de la llamada
      //console.log('start_callAPI_time', start_callAPI_time.toFixed(2) + ' segundos');
      let end_callAPI_time = 0
      let start_response_time = 0
      let end_response_time = 0      
      let total_response_time = 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
      let total_callAPI_time = 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
      let contAux = 0;
      
      // Manejo del tiempo de espera (20 segundos)
      const timeoutPromise = new Promise<void>((_, reject) => 
        setTimeout(() => reject(new Error('timeout')), 40000) // 20 segundos de espera
      );
  
      // Promesa que maneja la respuesta del evento
      const responsePromise = new Promise<void>((resolve) => {
        events.onmessage = (event) => {
          const parsedData = event as any;
          let mensaje_stream = JSON.parse(parsedData.data).response.message;
          this.showCarrusel = JSON.parse(parsedData.data).response.carrusel == 'yes';
          let source = JSON.parse(parsedData.data).source;
          let question_id = JSON.parse(parsedData.data).question_id;

          this.ngZone.run(() => {
            this.source = source;
            this.sourceLog.push([source, mensaje_stream]);

            if (contAux < 1){
              end_callAPI_time = performance.now(); // Tiempo de inicio de la llamada
              //console.log('end_callAPI_time', end_callAPI_time + ' segundos');
              start_response_time = performance.now(); // Tiempo de inicio de la llamada
              //console.log('start_response_time', start_response_time + ' segundos');
            }
            contAux++
            //console.log(contAux)
          });

          if (JSON.parse(parsedData.data).response.message !== undefined && JSON.parse(parsedData.data).response.message != '') {
            this.ngZone.run(() => {
              //console.log('respuesta orquestador 1: ' + mensaje_stream);
              this.filterMessage(mensaje_stream, source, question_id);
            });
          }

          if (JSON.parse(parsedData.data).response.document_url !== undefined) {
            console.log('document_url: ', JSON.parse(parsedData.data).response.document_url);
            this.ngZone.run(() => {
              this.chatLog.push(['', this.date, JSON.parse(parsedData.data).response.document_url, "default", undefined, undefined, undefined]);
              this.chatLogId.push('vm_' + this.chatLog.length);
            });
          }

          if (JSON.parse(parsedData.data).response.last_message !== undefined && JSON.parse(parsedData.data).response.last_message) {
            //console.log('last_message: ', JSON.parse(parsedData.data).response.last_message);
            this.ngZone.run(async () => {
              if (this.showTarifas && !newCicle && !this.wantKnowMore) {
                this.finalOfChat = true;
              } else {
                this.finalOfChat = false;
              }
              this.checkShowFeedback();

              let auxText = this.chatLog.length > 0 && this.chatLog[this.chatLog.length - 1][2] && this.chatLog[this.chatLog.length - 1][2][0]
                ? this.chatLog[this.chatLog.length - 1][2][0].label
                : null;

              let auxTextDispositivos = this.chatLog.length > 1 && this.chatLog[this.chatLog.length - 2][2]
                ? this.chatLog[this.chatLog.length - 2][2]
                : null;

              let auxTextNo = this.chatLog.length > 2 
                ? this.chatLog[this.chatLog.length - 3]
                : null;

              let auxTextDispositivos2 = this.chatLog.length >= 5 && this.chatLog[this.chatLog.length - 5][2]
                ? this.chatLog[this.chatLog.length - 5][2]
                : null;

              //console.log('auxText', auxText)
              //console.log('auxTextDispositivos', auxTextDispositivos)
              //console.log('auxTextNo', auxTextNo)
              //console.log('auxTextDispositivos2', auxTextDispositivos2)
              if (auxText != undefined && (auxText.includes('Fibra de 600MB')
                || auxText.includes('1 línea móvil') || auxText.includes('25 GB')
                || auxTextDispositivos.includes('dispositivos')
                || (auxTextDispositivos.includes('dispositivos') && auxTextNo && auxTextNo.includes('No'))
                || auxText.includes('Fibra y móvil')
                || (auxTextDispositivos2 && auxTextDispositivos2.includes('dispositivos')))) {
                this.finalOfChat = true;
              } else {
                this.finalOfChat = false;
              }
              this.chatEntryInputValue = '';

              if (this.wantKnowMore && !this.wantKnowMoreQuestionDone) {
                this.wantKnowMoreQuestionDone = true;
              } else if (this.wantKnowMore && this.wantKnowMoreQuestionDone && this.showCarrusel) {
                let indexLastCarrusel = this.chatLog.length - 1;
                this.chatLog.forEach((element, index) => {
                  if (element[2].includes('tarifaCarrousel')) {
                    indexLastCarrusel = index;
                  }
                });
                this.searchTarifas = this.chatLog[indexLastCarrusel];
                this.filterMessage(this.searchTarifas[2], source, question_id);
                setTimeout(() => {
                  this.searchTarifas = [];
                  this.wantKnowMore = false;
                  this.wantKnowMoreQuestionDone = false;
                  this.finalOfChat = true;
                });
              }

              if (this.showTarifas) {
                this.finalOfChat = false;
              }

              // Función para mostrar los iconos del feedback cuando termina el orquestador
              this.checkShowFeedback();
                          
              end_response_time = performance.now(); // Tiempo de fin de la llamada  
              //console.log('end_response_time', end_response_time.toFixed(2) + ' segundos');          
              total_response_time = start_response_time ? (end_response_time - start_response_time) / 1000 : 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
              total_callAPI_time = end_callAPI_time ? (end_callAPI_time - start_callAPI_time) / 1000 : 0; // Tiempo desde que se llama a la API hasta que contesta en segundos
              console.log('Tiempo que tarda en contestar la llamada:', total_callAPI_time + ' segundos');
              console.log('Tiempo que tarda desde que empieza a contestar hasta que termina:', total_response_time + ' segundos');
      
              //console.log('Historial source ', this.sourceLog);
              console.log('Historial ', this.chatLog);
              console.log('FIN');
              console.log('');
              
              if(JSON.parse(parsedData.data).insert_bbdd && total_callAPI_time != 0 && total_response_time != 0){
                this.callApiBBD(
                  JSON.parse(parsedData.data).insert_bbdd,
                  JSON.parse(parsedData.data).response,
                  total_callAPI_time.toFixed(4),
                  total_response_time.toFixed(4)
                )
              }

              events.close(); // Cerrar la conexión si hay un timeout
              resolve();
            });
          }
        };
      });

      try {
        await Promise.race([responsePromise, timeoutPromise]);
      } catch (error) {
        console.error('Error en la llamada al orquestador:', error);
        this.ngZone.run(() => {
          const errorMessage = 'Vaya, parece que estoy teniendo problemas con la conexión. Inténtalo de nuevo más adelante.'
          this.chatLog.push(['', this.date, errorMessage, "default", undefined, undefined, undefined]);
          this.callApiBBD(null, errorMessage, null, null);
          this.finalOfChat = false; // Permite que el usuario vuelva a intentar
        });
        events.close(); // Cerrar la conexión en caso de error
      }
    }
  }

  /**
   * funcion que guarda los datos en la bbdd
   * @param insert_bbdd 
   * @param response 
   */
  async callApiBBD(insert_bbdd: any, response: any, start_response_time:any, total_response_time:any) {
    //console.log('insert_bbdd', insert_bbdd)
    let urlAUX = this.url_bbdd
    let body;
    if(insert_bbdd != null && this.session_id.length > 1){
      body = {
        question_id: insert_bbdd.question_id,
        session_id: insert_bbdd.session_id,
        input_text: insert_bbdd.input_text,
        response_text: response.carrusel_response ? response.carrusel_response : insert_bbdd.response_text,
        adobe_id: this.adobeID,
        agente: insert_bbdd.agente,
        tag: insert_bbdd.tag,
        entidades: insert_bbdd.entidades,
        context: insert_bbdd.context,
        web_domain: insert_bbdd.web_domain,
        start_response_time: start_response_time,
        total_response_time: total_response_time
      }
    } else {
      body = {
        question_id: "null",
        session_id: this.session_id,
        input_text: response,
        response_text: response,
        adobe_id: this.adobeID ? this.adobeID : "null",
        agente: "-1",
        tag: "null",
        entidades: "null",
        context: "null",
        web_domain: "null",
        start_response_time: "null",
        total_response_time: "null"
      }
    }
  
    //console.log('insert_bbdd body', body)
  
    const responseAPI = await fetch(urlAUX, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  
    const data = await responseAPI.json()
    //console.log(data)
  
    // comprobamos si es el último mensaje que devuelve el orquestador
    if (data) {
      this.ngZone.run(() => {
        //console.log(data)
      });
    }
  }
  
  selectTarifa(tarifaCard: string): void {
    if (this.selectedTarifa != tarifaCard) {
      this.selectedTarifa = tarifaCard; // Guarda la tarifa seleccionada
      console.log('Tarifa seleccionada:', this.selectedTarifa);
    } else{ this.selectedTarifa = '' }
  }

  /**
   * funcion para mostrar los iconos del feecback cuando termina el orquestador
   */
  checkShowFeedback() {
    this.chatLog.forEach(element => {
      //si el elemento contiene la etiqueta de feedback modificamos el valor de una propiedad de esa fila del listado
      if (element[4] == 'feedback') {
        element[6] = true;
      }
    });
  }

  /**
   * funcion que envia pregunta fija al orquestador solo desde la ventana principal
   * @param input 
   */
  callOrquestadorFistTime(input: string) {
    if(!this.conversationStarterEvent) {
      window.parent.postMessage('conversation-starter-event=' + input, '*');
      this.conversationStarterEvent = true
    }
    if (input !== "") { //IS NOT EMPTY ?
      this.chatEntryInputValue = input
      this.setInstance("3"); //THEN GO TO CONVERSATIONAL INSTANCE
    }
  }

  /**
   * FUNCTION OF CHAT PREVIEW INSTANCE
   * USED TO AVOID AN EMPTY MESSAGE ON THE CONVERSATIONAL INSTANCE
   * @param input 
   */
  checkEntryInput(input: string) {
    if(!this.conversationStarterEvent) {
      window.parent.postMessage('conversation-starter-event=' + input, '*');
      this.conversationStarterEvent = true
    }
    if (input !== "") { //IS NOT EMPTY ?
      this.setInstance("3"); //THEN GO TO CONVERSATIONAL INSTANCE
    }
  }

  /**
   * FUNCION THAT ADD THE CHAT TO CHATLOG
   * [ HTML LOAD CHATLOG MESSAGES ]
   * @param optionalInput 
   * @param event 
   */
  async addChat(optionalInput?: any, event?: any) {  
    console.log('typeof addChat', typeof optionalInput)
    console.log('addChat', optionalInput)

    if (optionalInput.value == '') {
    } else if (optionalInput.value == '__empty_milvus__ orquestador') {
      await this.callOrquestador('__empty_milvus__', false, false)
    } else if (optionalInput.value == 'Llamar a un agente' || optionalInput.label == 'Llamar a un agente') {
      await this.callOrquestadorNoResponse('Llamar a un agente')
      console.log('llamar por telefono')
      let phoneNumber =  'tel:+34900100100'
      // Crear un enlace de teléfono
      const enlaceTeléfono = `tel:${this.phone1? this.phone1 : phoneNumber}`;
      // Abrir el enlace en el navegador
      window.location.href = enlaceTeléfono;
    } else if (optionalInput.value == 'quiero contratar orquestador') {
      await this.callOrquestador('quiero contratar', false, false)
    } else if (optionalInput.value == 'Seguir explorando orquestador') {
      //this.callOrquestador('quiero saber más', false, false)
      this.printText('¿En qué otra cosa te puedo ayudar?')
    } else if (typeof optionalInput == 'object'){
      //console.log('Usuario envia object:', optionalInput.value.input.text)
      if(optionalInput.value.input.text === 'Sí' &&  this.chatLog[this.chatLog.length-3][0] === '¿Qué ofertas de Black Friday tenéis en Vodafone?') {
        window.open('https://www.vodafone.es/c/tienda-online/particulares/ofertas-catalogo/', '_blank');
        this.finalOfChat = false
      } else {
        if (this.chatLog.length > 1 && this.chatLog[this.chatLog.length-2][2] == '¿Quieres información acerca de los dispositivos que tenemos disponibles en Vodafone?' && !optionalInput.value.input.text.includes('No')) {
            //console.log('Redirigir URL ')
            window.open(this.urlDispositivos, '_blank');
        } else {
          await this.callOrquestador(optionalInput.value.input.text, true, false)
        }
      }
    } else {
      //console.log('Usuario envia:', optionalInput)
      await this.callOrquestador(optionalInput, true, false)
    } 
  }

  /**
   * funcion que añade la hora al chat, llamada desde el html
   * @param event 
   */
  handleDateChange(event: any) {
    // Access the selected date value
    const selectedDate = event.target.value;

    // Call your function to check the value
    this.addChat(selectedDate, event);
  }

  /***
   * funcion que obtiene el string necesario de las tarifas para enviarlo al feedback
   */
  getTarifasForFeedback(value:string | any): string {
    // Reset the array before searching to avoid accumulating previous results
    let result = '';

    let matches = value.match(/##tarifaCarrousel##(.*?)##/g);
    matches.forEach((match: any) => {
      result += match
    });

    return result;
  }

  /**
   * Funcion que formatea el texto que recibimos del orquestador
   * @param iamessage 
   * @returns 
   */
  formatWatsonText(iamessage: string) {

    //console.log("⬜ WATSON TYPE TEXT")

    iamessage = iamessage.replace(/\.{3}/g, "[etc]");

    // Split the text into an array of sentences using period as the delimiter
    var breaks = iamessage.split(/\.(?!com|\.{3})/);

    // Join the sentences back together with a line break after each period
    var iamessageProccesed = breaks.join(".<br><br>");

    // Use a regular expression to match three consecutive <br> tags
    var regex = /<br\s*\/?>\s*<br\s*\/?>\s*<br\s*\/?>/gi;

    // Replace the matched pattern with a period and newline
    var iamessageProccesedBR = iamessageProccesed.replace(regex, '<br><br>');

    // Use a regular expression to match a single <br> tag with optional white space
    var regex = /(?<!<br\s*\/?>)\s*<br\s*\/?>\s*(?!<br\s*\/?>)/gi;

    // Replace the matched pattern with two <br> tags
    var iamessageProccesedBR = iamessageProccesedBR.replace(regex, '<br>');

    // Define the pattern for the text you want to replace
    var pattern = /\[([^\]]+)\]\(([^)]+)\)/;

    // Find the match in the original text
    var match = iamessageProccesedBR.match(pattern);

    if (match) {
      var linkText = match[1]; // Text inside []
      var linkHref = match[2]; // Text inside ()

      // Replace the found text with the <a> tag
      iamessageProccesedBR = iamessageProccesedBR.replace(pattern, `</span><a href="${linkHref}">${linkText}</a><span>`);
    }

    iamessageProccesedBR = iamessageProccesedBR.replace(/\[etc\]/g, "...");

    return iamessageProccesedBR;
  }

  /**
   * Funcion que formatea el texto que recibimos del orquestador, elimina las etiquetas o caracteres raros
   * @param iamessage 
   * @returns 
   */
  formatNSText(iamessage: string) {

    //console.log("⬜ NS TYPE TEXT");
    console.log(iamessage)

    // Replace consecutive newline characters with an empty string
    iamessage = iamessage.replace(/\n/g, '');

    // Replace consecutive newline characters with an empty string
    iamessage = iamessage.replace(/<br>/g, '');

    // Remove consecutive occurrences of "<br><br>""
    iamessage = iamessage.replace(/<br><br>+/g, '');

    // Detect and replace ... with [etc]
    iamessage = iamessage.replace(/\.{3}/g, "[etc]\n");

    iamessage = iamessage.replace(/(\d+)\./g, "[$1dot]");

    iamessage = iamessage.replace(/:/g, ":\n");

    // Detect and replace EE. UU or EE.UU with [EEUU]
    iamessage = iamessage.replace(/EE(\.|\. |\. )?UU(\.| )?/g, '[EEUU]');

    var breaks = iamessage.split(/\.(?!com|\.{3})/);

    for (var i = 0; i < breaks.length; i++) {
      breaks[i] = breaks[i].trim(); // Remove white space after the dot
    }

    iamessage = breaks.join(".\n\n");

    // If the text ends with "\n", remove it
    iamessageProcessed = iamessage.replace(/\n$/, '');

    var breaks = iamessageProcessed.split('<br>');
    var iamessageProcessed = breaks.join("\n");

    // Use a regular expression to match three consecutive <br> tags
    var regex = /<br\s*\/?>\s*<br\s*\/?>\s*<br\s*\/?>/gi;

    // Replace the matched pattern with a period and newline
    var iamessageProcessedBR = iamessageProcessed.replace(regex, '\n \n');

    // Replace [etc] with ...
    iamessageProcessedBR = iamessageProcessedBR.replace(/\[etc\]/g, "...");
    iamessageProcessedBR = iamessageProcessedBR.replace(/\[(\d+)dot\]/g, "$1.");
    // Replace [EEUU] with EE.UU
    iamessageProcessedBR = iamessageProcessedBR.replace(/\[EEUU\]/g, 'EE.UU');

    iamessageProcessedBR = iamessageProcessedBR.replace(/(¿Quieres preguntarme algo más\?)/g, "\n$1");

    iamessageProcessedBR = iamessageProcessedBR.replace(/(\*\s[\w\d\s])/g, "\n\n$1");

    return iamessageProcessedBR;
  }

  /**
   * funcion que procesa el nombre de la tarifa y lo devuelve en minusculas con la ruta de la imagen
   * @param tarifaName 
   * @returns 
   */
  processImagePath(tarifaName: string) {
    // Remove white spaces and accent marks
    const tarifaNameNew = tarifaName.replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    //console.log('processImagePath - tarifaNameNew:', tarifaNameNew.toLowerCase())
    //console.log('../assets/images/tarifas/' + tarifaNameNew.toLowerCase() + 'mini.webp')

    // Convert to lowercase
    //return '../assets/images/tarifas/' + tarifaNameNew.toLowerCase() + '.webp';
    return '../assets/images/tarifas/new/' + tarifaNameNew.toLowerCase() + '.png';
  }
  

  /**
   * funcion que abre y cierra el modal, tambien vacia la caja de texto para que el usuario pueda volver a escribir
   */
  openIAModal() {
    this.modalSwitch = !this.modalSwitch;    
    this.chatEntryInputValue = "";
  }

  /**
   * funcion cuando pulsan el boton quiero saber más, recibe el nombre de la tarifa y el evento
   * @param tarifaCard 
   * @param event 
   */
  async iWantToKnowMore(tarifaCard: string, event?: any) {
    this.wantKnowMore = true
    this.tarifaCardNameSelected = tarifaCard
    tarifaCard = tarifaCard.replace(/\n/g, '');
    await this.addChat('Quiero saber más acerca de la tarifa ' + tarifaCard, event)
  }

  /**
   * funcion que abre una nueva pestaña y pinta de nuevo la tarifa sin el boton de lo quiero
   * @param url 
   */
  iWantIt(tarifaCard: any, question_id: any, lead:any){
    window.open(tarifaCard[3], '_blank');
    this.ngZone.run(() => {
      this.onlyMoreBtn = true;
      if (!question_id){
        question_id = this.question_id
      } else {
        this.question_id = question_id
      }
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push(['', this.date, tarifaCard, "default tarifaCard", tarifaCard[0], [tarifaCard], true, undefined]);
      this.chatLogId.push('vm_'+this.chatLog.length)
      //console.log('callOrquestador - chatLog length ', this.chatLog.length);
      //console.log('Historial source ', this.sourceLog)
      console.log('Historial', this.chatLog);
      console.log('')
      /*
      this.loadFrame(tarifaCard[0]);

      */
      //envio feedback cuando pulsamos al boton lo quiero
      this.sendFeedbackIWantItBtnNoResponse(question_id, lead)
    });
  }

  /**
   * la funcion que muestra el iframe en el front
   */
  loadFrame(tarifaCard: string) {
    this.ngZone.run(() => {
      this.currentChatPath = "secondInteraction";
      //desactivamos el modal
      this.modalSwitch = false;
      //this.chatEntryInputValue = 'Quiero saber mas'
      this.chatEntryInputValue = ''
      //this.showWeb = true;
      //console.log('loadFrame - showWeb ', this.showWeb)
      //this.instance = '0';
      //this.widthIframe = window.innerWidth;
      //this.heightIframe = window.innerHeight;
      this.callOrquestadorNoResponse(tarifaCard)
    });
  }

  /**
   * funcion llamada cuando se pulsa la bolita de la IA para que mueste la pantalla donde se interactua con la IA
   */
  talkToIA() {
    //console.log('talkToIA')
     //console.log('talkToIA - chatLog.length', this.chatLog.length)
    if (this.chatLog.length > 0) {
       //console.log('talkToIA - hay historial')
       //console.log('talkToIA - chatEntryInputValue', this.chatEntryInputValue)
      this.instance = '3';
      if (this.chatEntryInputValue != '') {
        this.callOrquestador(this.chatEntryInputValue, true, false)
      }
    } else {
      // console.log('talkToIA - no hay historial')
      if(this.withPhone && !this.askForPhone) {
        this.setInstance('4')
      } else {
        this.setInstance('2')
      }
    }
    console.log('instance: ', this.instance)
  }
  
  /**
   * funcion que llama al orquestador pasandole la tarifa pero no pinta la respuesta del orquestador en el front
   * @param valueToSend 
   */
  async callOrquestadorNoResponse(valueToSend: string){
    if(valueToSend.length > 0) {
      console.log('INICIO')
      console.log('Call Orquestador - enviamos: ', valueToSend)
      //inicializamos la fecha y hora
      this.date = new Date();
      //variable donde guardamos el mesnaje del chatBot que mostraremos en el front
      this.iamessage = '';
      //variable donde vamos a guardar todas las opciones de tarifas que recibamos de la consulta
      this.iaoptions = [];
      //variable que oculta el boton de Lo quiero cuando esta en true
      this.onlyMoreBtn = false;

      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      //const events = new EventSource('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id);
      //console.log('url orquestador:', this.url_servicio + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      const events = new EventSource(this.url_servicio + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id);
      events.onmessage = (event) => {
        const parsedData = event as any;
        
          //comprobamos si es el último mensaje que devuelve el orquestador
        if (JSON.parse(parsedData.data).response.last_message !== undefined && JSON.parse(parsedData.data).response.last_message) {
          //console.log('callOrquestadorNoResponse - mensaje_stream: ', this.chatLog[this.chatLog.length-1][2])
          //console.log('callOrquestadorNoResponse FIN - last_message: ', JSON.parse(parsedData.data).response.last_message)
          console.log('FIN')
          events.close();
          this.ngZone.run(() => {
            //console.log('Historial source ', this.sourceLog)
            console.log('Historial', this.chatLog)
            console.log('')
            
            //vaciamos la caja de texto para que el usuario pueda volver a escribir
            this.chatEntryInputValue = '';
          });
        }
      };
    }
  }
  
  /**
   * funcion que llama al orquestador pasandole la tarifa pero no pinta la respuesta del orquestador en el front
   * @param valueToSend 
   */
  async sendFeedbackNoResponse(valueToSend: any, question_id: any){
    console.log('INICIO')
    console.log('send Feedback - enviamos: ', valueToSend)

    var imgBtn = document.getElementById(question_id+valueToSend)
    //console.log(imgBtn)
    imgBtn?.classList.add('feedback_selected')
    var chatBotBtn = document.getElementById(question_id)
    chatBotBtn?.classList.add('removePointerEvents')

    //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
    //const events = new EventSource('http://localhost:8000/postgre?question_id=573865737&session_id=38537783738&feedback=positivo');
    //console.log('url orquestador:', this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&feedback='+ encodeURIComponent(valueToSend))
    const events = new EventSource(this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&feedback='+ encodeURIComponent(valueToSend));
    events.onmessage = (event) => {
      const parsedData = event as any;
    };

    if(valueToSend == 0 && !this.showCarrusel){
      //this.callOrquestadorNoResponse('__empty_milvus__')
      //añadimos el texto y 3 botones
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push([
        '',
        this.date,
        '¡Vaya! Siento que la respuesta no te haya gustado. ¿En qué más te puedo ayudar?',//'Siento que no te haya gustado mi respuesta. ¿Quieres contactar con un agente?',
        "default",
        undefined,
        undefined,
        false,
        undefined
      ]);
      this.chatLog.push([
        '',
        this.date,
        [{label:'Encontrar la mejor tarifa para mi', value:'quiero contratar orquestador'},
        {label:'Seguir explorando', value:'Seguir explorando orquestador'},
        {label:'Llamar a un agente', value:'Llamar a un agente'}],
        "default",
        undefined,
        undefined,
        undefined
      ]);
      this.sourceLog.push(['CODE',''])
      this.sourceLog.push(['CODE',''])
      //console.log('Historial sourceLog: ', this.sourceLog)
      console.log('Historial: ', this.chatLog)
    }
  }

  printText(textToPrint: string) {
    //console.log('printText', textToPrint)
    this.chatLog.push([
      '',
      this.date,
      textToPrint,
      "default",
      undefined,
      undefined,
      undefined
    ]);
    this.sourceLog.push(['CODE',''])
    console.log('Historial: ', this.chatLog)
  }

  checkIfDifferentCarrusel(message: any){ 
    let result = false   
    //console.log('checkIfDifferentCarrusel')
    //console.log('message', message)
    this.chatLog.forEach(element => {      
      if(element[2]!= undefined && element[2].includes('##')){ 
        //console.log('element[2]', element[2])
        if(element[2] == message){
          result = true
          //console.log('MISMA TARIFA')
        }
      }
    });
    return result
  }

  async sendFeedbackCarruselNoResponse(question_id: any, message: any, carrusel: any){
      //console.log('sendFeedbackCarruselNoResponse')
    let result = this.checkIfDifferentCarrusel(message)
    if(question_id.length > 0 && result) {
      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      //const events = new EventSource('http://localhost:8000/postgre?question_id=573865737&session_id=38537783738&feedback=positivo');
      //https://orquestador.1gppixdrrwu6.eu-de.codeengine.appdomain.cloud/postgre?question_id=942b80d6-7320-4322-bd48-d5dea28ae32b&session_id=e324d72d-e2de-408b-bcba-4961ff5b1b83&carrusel=''
      //console.log('url orquestador:', this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&carrusel=' + encodeURIComponent(carrusel))
      const events = new EventSource(this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&carrusel=' + encodeURIComponent(carrusel));
      events.onmessage = (event) => {
        const parsedData = event as any;
      };
    }
  }
  async sendFeedbackIWantItBtnNoResponse(question_id: any, lead:any){
    if(question_id.length > 0) {
      //console.log('sendFeedbackIWantItBtnNoResponse')

      //console.log('http://localhost:8000/sse?prompt=' + encodeURIComponent(valueToSend) + '&session_id='+ this.session_id)
      //const events = new EventSource('http://localhost:8000/postgre?question_id=573865737&session_id=38537783738&feedback=positivo');
      //https://orquestador.1gppixdrrwu6.eu-de.codeengine.appdomain.cloud/postgre?question_id=942b80d6-7320-4322-bd48-d5dea28ae32b&session_id=e324d72d-e2de-408b-bcba-4961ff5b1b83&lead=''
      //console.log('url orquestador:', this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&lead=1')
      const events = new EventSource(this.url_feedback + 'question_id=' + question_id + '&session_id=' + this.session_id + '&lead=' + lead);
      events.onmessage = (event) => {
        const parsedData = event as any;
      };
    }
  }

  /**
   * Funcion que obtiene la url a pintar segun el parametro que reciba con el nombre de la tarifa seleccionada
   * @param imgName 
   */
  
  getLinkImage(imgName: string) {
    const tarifaNameNew = imgName.replace(/\s/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    //console.log('getLinkImage - tarifaNameNew: ', tarifaNameNew)
    let url = this.vodafoneWeb
    switch(tarifaNameNew){
      case 'fibra1gbps+2lineasmovilesilimitadas+max,disney+yprime':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?fiber=1Gbps&line1=Infinito&line2=Infinito&tv=TodasOTTs&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+2lineasmovilesilimitadas+serieloversofamilylovers':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2784065&fiber=600Mbps&line1=Infinito&line2=Infinito&tv=Serielovers&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+2movil25gb':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2765137&fiber=600Mbps&line1=25GB&line2=25GB&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+2movil50gb+serieloversofamilylovers':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2765514&fiber=600Mbps&line1=50GB&line2=50GB&tv=Serielovers&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+2movil100gb':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2765197&fiber=600Mbps&line1=100GB&line2=100GB&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+2movil100gb+serieloversofamilylovers':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2765496&fiber=600Mbps&line1=100GB&line2=100GB&tv=Serielovers&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+movil25gb':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2764994&fiber=600Mbps&line1=25GB&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+movil25gb+serieloversofamilylovers':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2765477&fiber=600Mbps&line1=25GB&tv=Serielovers&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+movil100gb':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2765012&fiber=600Mbps&line1=100GB&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+movil100gb+seriefansofamilyfans':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2765306&fiber=600Mbps&line1=100GB&tv=Seriefans&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      case 'fibra600mbps+movililimitado+seriefansofamilyfans':
        url = 'https://www.vodafone.es/c/particulares/es/configurador-fibra-movil/?id3=2784019&fiber=600Mbps&line1=Infinito&tv=Seriefans&entrypoint=configurador%20intermedio&entryloc=vodafone%20m%C3%A1gica'
        break;
      default:
        url = 'https://vodafone.es&entrypoint=vodafone%20magica&entryloc=not%20defined'
        break
    }
    //console.log('getLinkImage - url: ', url)
    return url
  }

  /**
   * funcion que vuelve a mostrar la tarifa despues de la respuesta al pulsar el boton saber más
   */
  async showCardAgain() {    
    this.ngZone.run(() => {
      let listCardSelected = [];
      listCardSelected.push(this.tarifaCardNameSelected);
      //this.chatLog.push([textousuario,fecha,textoChatIA,estilosCSS,listaTarifas, mostrar u ocultar boton lo quiero, listado de precios])
      this.chatLog.push(['', this.date, this.tarifaCardNameSelected, "default tarifaCard", this.tarifaCardNameSelected, listCardSelected, false, undefined]);
      this.chatLogId.push('vm_'+this.chatLog.length)
      //console.log('showCardAgain - chatLog length ', this.chatLog.length);
      //console.log('showCardAgain - chatLog ', this.chatLog);
      //console.log('showCardAgain - chatLogId ', this.chatLogId);
      this.wantKnowMore = false;
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Oper
      //document.body.scrollTop = 0; // For Safari
      //console.log('showCardAgain - id=1 ', document.getElementById('1'));
      //document.getElementById('1')?.focus();
      //document.getElementById('1')?.scroll(0,0);
    });
  }


  /**
   * funcion llamada desde el html cuando se pulsa un boton de TV
   * @param value 
   */
  selectTV(value: string){
    const hbo_img = document.getElementById('hbo_img');
    const disney_img = document.getElementById('disney_img');
    if (hbo_img && disney_img){
      if(value == 'hbo_img'){
        hbo_img.className = "imgSelected";
        disney_img.className = "";
      } else if(value == 'disney_img'){
        hbo_img.className = "";
        disney_img.className = "imgSelected";
      }
    }
    this.tvSelected = value
  }

  /**
   * funcion que envia a donde se llame el iframe el numero de telefono
   */
  savePhone() {
    // Comprobar si el valor de userPhone es un número de teléfono válido
    this.askForPhone = true
    if (this.errorPhone) {
      // Lógica para guardar el número de teléfono
      console.log('Número de teléfono válido');
      // enviamos el telefono desde el iframe
      window.parent.postMessage(this.userPhone, '*');
      window.parent.postMessage('acceptPrivacyPolicy=' + this.acceptPrivacyPolicy, '*');
      window.parent.postMessage('acceptCommercialCommunications' + this.acceptCommercialCommunications, '*');    
      //console.log(this.userPhone, this.acceptPrivacyPolicy, this.acceptCommercialCommunications);
      console.log('Se ha enviado el teléfono');
      this.setInstance('2')
    } else {
      this.errorPhone = false
      // Mostrar un mensaje de error o realizar otra acción
      console.error('Número de teléfono no válido');
    }
  }

  /**
   * funcion que comprueba que el formato del telefono es valido
   * @param phone 
   * @returns true or false
   */
  isValidPhone(phone: string): boolean {
    // Expresión regular para validar el formato de un número de teléfono
    const phoneRegex = /^[0-9]{9}$/;
    return phoneRegex.test(phone);
  }
  
  /**
   * funcion que comprueba que el formato del telefono es valido
   */
  validatePhone() {
    // Agrega tu lógica de validación de teléfono aquí
    //console.log('phone:', this.userPhone);
    this.errorPhone = this.isValidPhone(this.userPhone);
    this.checkButton()
  }

  /**
   * funcion que cierra el popup
   */
  closePopup() {
    this.setInstance('1')
  }

  /**
   * funcion que es llamada al pulsar el boton de enviar la info del telefono
   */
  checkButton(){
    if(this.userPhone.length == 9 && this.errorPhone && this.acceptPrivacyPolicy)
      this.disabledSendBtn = false
    else 
      this.disabledSendBtn = true
  }

  /**
   * funcion para minimizar la ventana y manda el numero de la instancia a donde se llama el iframe
   */
  minimize() {
    this.setInstance('1');
    console.log('instance: ', this.instance)
    window.parent.postMessage('minimizar', '*');
    let instanceAux = this.instanceToSend()
    window.parent.postMessage('instance=' + instanceAux, '*');
    console.log('instanceToSend: ', instanceAux)
  }

  /**
   * funcion para minimizar la ventana y manda el numero de la instancia a donde se llama el iframe
   */
  minimizeFromChat() {
    //console.log('minimizeFromChat')
    this.chatLog.length > 0 ? this.setInstance('1'): this.setInstance('2')
    sessionStorage.setItem('chatLog', JSON.stringify(this.chatLog));
    sessionStorage.setItem('sourceLog', JSON.stringify(this.sourceLog));
    window.parent.postMessage('minimizar', '*');
    console.log('instance: ', this.instance)
    let instanceAux = this.instanceToSend()
    console.log('instanceToSend: ', instanceAux)
    window.parent.postMessage('instance=' + instanceAux, '*');
  }

  /**
   * funcion que comprueba si hay conversacion guardada en memoria de la sesion del navegador o no,
   * si hay conversacion en memoria pone la instancia a 3 (pantalla blanca)
   * si no hay conversacion en memoria pone la instancia a 2 (pantalla negra)
   * @returns 
   */
  instanceToSend() {
    let result = this.instance
    // Obtener el valor guardado en el almacenamiento local
    const chatLogsessionStorage = sessionStorage.getItem('chatLog');
    const sourceLogsessionStorage = sessionStorage.getItem('sourceLog');

    if (chatLogsessionStorage && sourceLogsessionStorage) {
      result = '3'
    } else {
      result = '2'
    }
    return result
  }

  getSanitizedMessage(message: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(message.replace(/\n/g, '<br>'));
  }
}